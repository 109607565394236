<template>
  <svg
    role="img"
    class="the-greene-space-logo"
    aria-label="The Greene Space Logo"
    width="39"
    height="31"
    viewBox="0 0 39 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8185)">
      <path
        d="M0.794922 15.9978H9.20768V13.4625H0.794922V15.9978Z"
        fill="white"
      />
      <path
        d="M10.6875 15.9984H19.0999V2.76367H10.6875V15.9984Z"
        fill="white"
      />
      <path
        d="M10.6875 15.9978H19.1001V13.4625H10.6875V15.9978Z"
        fill="white"
      />
      <path
        d="M20.5781 15.9969H28.9908V4.09717H20.5781V15.9969Z"
        fill="white"
      />
      <path
        d="M30.4707 15.9985H38.8824V0.996826H30.4707V15.9985Z"
        fill="white"
      />
      <path
        d="M12.1786 18.0526V20.7529H11.4808V18.0526H10.6387V17.4135H13.0159V18.0526H12.1786Z"
        fill="white"
      />
      <path
        d="M15.4412 20.7529V19.393H14.3295V20.7529H13.6367V17.4135H14.3295V18.7539H15.4412V17.4135H16.1342V20.7529H15.4412Z"
        fill="white"
      />
      <path
        d="M16.9512 20.7529V17.4135H19.1164V18.0337H17.6391V18.7636H18.9384V19.3835H17.6391V20.1326H19.1357V20.7529H16.9512Z"
        fill="white"
      />
      <path
        d="M12.2786 25.4978C11.3691 25.4978 10.7148 24.8442 10.7148 23.8043V23.7614C10.7148 22.7738 11.3789 22.0627 12.2883 22.0627C12.7646 22.0627 13.0678 22.1868 13.3518 22.4112L13.0245 22.9835C12.7984 22.807 12.5962 22.7071 12.2786 22.7071C11.7974 22.7071 11.4366 23.1364 11.4366 23.7471V23.8043C11.4366 24.4961 11.812 24.8683 12.3027 24.8683C12.4954 24.8683 12.6636 24.8203 12.8082 24.7299V24.1382H12.1968V23.537H13.4673V25.0828C13.1641 25.3165 12.7743 25.4978 12.2786 25.4978Z"
        fill="white"
      />
      <path
        d="M15.9585 25.4479L15.3425 24.3507H14.8998V25.4479H14.207V22.1084H15.4628C16.1991 22.1084 16.666 22.4899 16.666 23.2008V23.2153C16.666 23.7257 16.4061 24.0453 16.0405 24.2074L16.7525 25.4479H15.9585ZM15.9633 23.2294C15.9633 22.8811 15.7469 22.7332 15.4196 22.7332H14.8998V23.7399H15.4341C15.7612 23.7399 15.9633 23.5635 15.9633 23.2486V23.2294Z"
        fill="white"
      />
      <path
        d="M17.3613 25.4479V22.1084H19.5266V22.7286H18.0492V23.4587H19.3486V24.0786H18.0492V24.8278H19.5459V25.4479H17.3613Z"
        fill="white"
      />
      <path
        d="M20.2363 25.4479V22.1084H22.4017V22.7286H20.9244V23.4587H22.2237V24.0786H20.9244V24.8278H22.4209V25.4479H20.2363Z"
        fill="white"
      />
      <path
        d="M25.1219 25.4479L23.7939 23.4298V25.4479H23.1152V22.1084H23.7552L25.0351 24.0548V22.1084H25.7234V25.4479H25.1219Z"
        fill="white"
      />
      <path
        d="M26.5352 25.4479V22.1084H28.7006V22.7286H27.2232V23.4587H28.5224V24.0786H27.2232V24.8278H28.7197V25.4479H26.5352Z"
        fill="white"
      />
      <path
        d="M11.9629 30.075C11.5346 30.075 11.116 29.9369 10.7793 29.66L11.0873 29.116C11.3614 29.3261 11.6359 29.455 11.9487 29.455C12.2131 29.455 12.3818 29.3498 12.3818 29.1497V29.1401C12.3818 28.9636 12.2902 28.8632 11.8138 28.6676C11.217 28.4102 10.8609 28.2193 10.8609 27.6323V27.6229C10.8609 27.0408 11.3086 26.6591 11.9341 26.6591C12.3431 26.6591 12.6801 26.7734 12.9639 26.9835L12.6943 27.5514C12.4297 27.3797 12.1941 27.2793 11.9438 27.2793C11.6984 27.2793 11.5539 27.3986 11.5539 27.5654V27.5749C11.5539 27.7803 11.6694 27.8423 12.1602 28.0475C12.7762 28.329 13.0745 28.5627 13.0745 29.0829V29.0922C13.0745 29.7124 12.6078 30.075 11.9629 30.075Z"
        fill="white"
      />
      <path
        d="M14.8199 29.0197H14.4253V30.0357H13.7324V26.6963H14.8486C15.6042 26.6963 16.0806 27.1303 16.0806 27.8411V27.8508C16.0806 28.6186 15.5272 29.0053 14.8199 29.0197ZM15.3782 27.8556C15.3782 27.4978 15.1663 27.3211 14.8296 27.3211H14.4253V28.3995H14.8344C15.1712 28.3995 15.3782 28.1893 15.3782 27.8652V27.8556Z"
        fill="white"
      />
      <path
        d="M18.4975 30.0382L18.2475 29.313H17.0923L16.842 30.0382H16.1445L17.3473 26.6794H18.0115L19.2147 30.0382H18.4975ZM17.67 27.6147L17.2993 28.7023H18.0357L17.67 27.6147Z"
        fill="white"
      />
      <path
        d="M20.9797 30.0857C20.1281 30.0857 19.4883 29.413 19.4883 28.3922V28.3493C19.4883 27.3329 20.1281 26.6506 21.0137 26.6506C21.5091 26.6506 21.8028 26.8035 22.0579 27.0323L21.7305 27.5906C21.5141 27.4093 21.2975 27.2949 21.0281 27.2949C20.5514 27.2949 20.2101 27.6907 20.2101 28.3397V28.3873C20.2101 29.0504 20.5467 29.4418 21.0328 29.4418C21.3264 29.4418 21.5283 29.3272 21.7499 29.1365L22.0818 29.6373C21.7978 29.9188 21.4852 30.0857 20.9797 30.0857Z"
        fill="white"
      />
      <path
        d="M22.7246 30.0357V26.6963H24.8899V27.3165H23.4127V28.0465H24.7118V28.6665H23.4127V29.4155H24.9092V30.0357H22.7246Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8185">
        <rect
          width="38.1966"
          height="29.5155"
          fill="white"
          transform="translate(0.796875 0.900391)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
