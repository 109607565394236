<template>
  <svg
    role="img"
    class="wnyc-logo"
    aria-label="WNYC Logo"
    width="56"
    height="23"
    viewBox="0 0 56 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: #ffffff"
      d="M28.8455 22.2778H41.111V4.90105H28.8455V22.2778ZM36.6373 8.73635H39.0708L36.0878 13.4803V16.5313H33.9006V13.5134L30.9178 8.73635H33.3965L35.0107 11.4756L36.6373 8.73635ZM43.2683 22.2788H55.5334V0.372437H43.2683V22.2788ZM49.6871 16.6871C47.4446 16.6871 45.6389 14.9844 45.6389 12.6561V12.6345C45.6389 10.3627 47.3992 8.5812 49.7765 8.5812C51.3799 8.5812 52.4119 9.24874 53.1071 10.2069L51.4702 11.465C51.0214 10.9083 50.506 10.5523 49.7544 10.5523C48.655 10.5523 47.8816 11.4756 47.8816 12.6119V12.6345C47.8816 13.8033 48.655 14.7164 49.7544 14.7164C50.573 14.7164 51.0554 14.3375 51.5254 13.7702L53.1636 14.9279C52.4234 15.9412 51.4252 16.6871 49.6871 16.6871ZM14.4226 22.2779H26.6885V2.95169H14.4226V22.2779ZM24.376 16.5314H22.4691L19.1167 12.2555V16.5314H16.9632V8.73631H18.9929L22.2226 12.8562V8.73631H24.376V16.5314ZM2.6624 16.5701H4.55793L6.12748 12.083L7.70945 16.5701H9.60432L12.228 8.71998H9.97467L8.60591 13.2303L7.09242 8.69799H5.21969L3.70576 13.2303L2.33773 8.71998H0.0380157L2.6624 16.5701ZM0 18.5757H12.2659V22.278H0V18.5757Z"
    />
  </svg>
</template>
