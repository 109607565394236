<template>
  <svg
    role="img"
    class="njpr-logo"
    aria-label="NJPR Logo"
    width="76"
    height="22"
    viewBox="0 0 76 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8147)">
      <path
        d="M72.6505 19.9451L68.8912 14.314L68.8674 14.2785C68.8258 14.2209 68.721 14.1131 68.5365 14.1511L68.5353 14.1499C68.3437 14.1756 68.152 14.1977 67.9651 14.2136C67.9056 14.2307 67.7306 14.2993 67.8675 14.5148L71.6006 20.0884C71.672 20.1754 71.7434 20.2219 71.8458 20.2219H72.5398C72.7505 20.2207 72.7278 20.0713 72.6505 19.9451V19.9451Z"
        fill="white"
      />
      <path
        d="M32.6982 0.581834V0.57571C32.6958 0.407927 32.5648 0.271988 32.4006 0.269531H32.0244C31.8637 0.271981 31.7339 0.404243 31.728 0.568353V12.4912C31.728 15.0679 29.6901 17.1633 27.1866 17.1633C26.2308 17.1633 25.2391 16.8094 24.4702 16.2375C24.2976 16.1199 24.225 16.1187 24.1119 16.2424L23.7738 16.573C23.6321 16.7212 23.6726 16.8388 23.769 16.9478C24.7285 17.7059 25.9712 18.1615 27.1866 18.1615C30.2257 18.1615 32.6981 15.619 32.6981 12.4923V0.582638L32.6982 0.581834Z"
        fill="white"
      />
      <path
        d="M47.8347 0.269531L38.3199 0.270756C38.1604 0.270756 38.0307 0.39812 38.0176 0.559785V19.9348C38.0307 20.0964 38.1604 20.2226 38.3199 20.2226H38.683C38.852 20.2226 38.9866 20.083 38.9866 19.9103L38.9877 1.49367C38.9913 1.36876 39.0889 1.26711 39.2115 1.26711H47.8336C51.3393 1.26711 54.1927 4.20148 54.1927 7.80826C54.1927 11.415 51.3404 14.3494 47.8336 14.3494H43.1649C43.0006 14.3519 42.8697 14.4841 42.8649 14.6531V14.6617V14.7903V19.9108V19.9144C42.8685 20.0859 43.0018 20.2231 43.1685 20.2231H43.5339C43.697 20.2231 43.8291 20.0908 43.8351 19.923V15.6783H43.8363V15.5815H43.8351V15.5791C43.8375 15.4517 43.9375 15.3476 44.0624 15.3476H44.1696H47.8336C51.8761 15.3476 55.1616 11.9661 55.1616 7.80955C55.164 3.65165 51.8773 0.270321 47.8347 0.270321L47.8347 0.269531Z"
        fill="white"
      />
      <path
        d="M67.4468 5.24829H62.7936C62.6377 5.25319 62.5115 5.38179 62.502 5.54222V9.98424C62.5115 10.1459 62.6388 10.2757 62.7984 10.2782H67.4469C68.8849 10.2782 69.8908 9.24453 69.8908 7.76269C69.8908 6.2808 68.8849 5.2484 67.4469 5.2484L67.4468 5.24829ZM67.4468 9.2824H63.6983C63.5757 9.28117 63.4757 9.18075 63.4733 9.0546V6.47419C63.4769 6.34927 63.5757 6.25007 63.6971 6.24762H67.4469C68.5349 6.24762 68.9206 7.06572 68.9206 7.76503C68.9206 8.46556 68.5349 9.28244 67.4469 9.28244L67.4468 9.2824Z"
        fill="white"
      />
      <path
        d="M47.816 5.24829H43.1639C43.0044 5.25319 42.8759 5.38669 42.8711 5.5508V5.56059V5.68796V9.98052C42.8794 10.1446 43.0068 10.2757 43.1675 10.2781H47.816C49.2313 10.2781 50.2599 9.22121 50.2599 7.76264C50.2599 6.30406 49.2314 5.24835 47.816 5.24835V5.24829ZM47.816 9.2824H44.0675C43.9437 9.28117 43.8437 9.18075 43.8413 9.05338V6.57704H43.8425V6.48029H43.8413V6.47906C43.8437 6.3517 43.9437 6.25005 44.0663 6.24759H47.8161C48.6827 6.24759 49.2897 6.87219 49.2897 7.765C49.2897 8.6578 48.6815 9.28241 47.8161 9.28241L47.816 9.2824Z"
        fill="white"
      />
      <path
        d="M69.9871 19.9608L66.2052 14.4044C66.1505 14.3248 66.0612 14.2696 65.9588 14.2684H62.8007C62.6412 14.2709 62.515 14.3983 62.502 14.5599V19.9253C62.5115 20.0894 62.6424 20.2205 62.8055 20.2205H63.1698C63.3376 20.2205 63.4733 20.0809 63.4733 19.9082H63.4721L63.4733 15.4931C63.4781 15.367 63.5769 15.2666 63.6995 15.2666H65.4792C65.5518 15.2666 65.6125 15.3033 65.6542 15.356L68.8979 20.0416C68.9765 20.1666 69.0539 20.2205 69.1729 20.2205H69.8669C70.0669 20.2205 70.0681 20.1004 69.9872 19.9608L69.9871 19.9608Z"
        fill="white"
      />
      <path
        d="M34.8141 0.268802H34.4379C34.2737 0.271252 34.1403 0.409644 34.1403 0.5811V12.6465C34.1403 16.5068 31.087 19.6456 27.3349 19.6456C25.435 19.6456 23.6078 18.819 22.3162 17.3714L21.8269 16.8435C21.7507 16.7517 21.7519 16.6145 21.8341 16.5263H21.8353L21.8376 16.5239L24.0661 14.2864C24.1542 14.2068 24.2887 14.208 24.3732 14.2937L24.3744 14.295L24.8589 14.791C25.4505 15.3898 26.248 15.7352 27.0849 15.7352C28.836 15.7352 30.2597 14.2704 30.2597 12.4689V0.555961C30.2478 0.396753 30.1217 0.270606 29.9645 0.26816H29.5884C29.4229 0.27061 29.2908 0.409001 29.2908 0.580458L29.2896 12.4682C29.2896 13.7186 28.3004 14.7375 27.085 14.7375C26.4588 14.7375 25.8601 14.4619 25.4375 13.9782L24.4423 12.9311C24.3245 12.8184 24.1423 12.8184 24.0257 12.9323L20.5141 16.4533L20.5057 16.4619L20.5034 16.4631C20.3867 16.5868 20.3867 16.7815 20.5045 16.904L21.6044 18.0515C23.0734 19.7 25.1614 20.643 27.3326 20.643C31.6204 20.643 35.107 17.0559 35.107 12.6458V0.561753C35.1011 0.398871 34.9737 0.270279 34.8142 0.267822L34.8141 0.268802Z"
        fill="white"
      />
      <path
        d="M13.7306 12.1563C13.8544 12.1563 13.9556 12.0534 13.9556 11.9236V11.9162V0.548276C13.9413 0.392741 13.8163 0.270268 13.6616 0.267822H13.2854C13.1212 0.271496 12.9878 0.408663 12.9878 0.578892L12.9854 9.55965L6.87157 0.488317C6.72991 0.309513 6.5823 0.269094 6.44422 0.269094L1.53979 0.26787C1.37551 0.271544 1.24338 0.409939 1.24338 0.578939L1.24219 19.9221C1.24933 20.0887 1.38146 20.2197 1.54455 20.2197H1.91C2.07665 20.2197 2.21236 20.0826 2.21355 19.9111V1.50108V1.49864C2.21355 1.37127 2.31354 1.26839 2.43734 1.26839L6.00615 1.26962C6.00615 1.26962 6.22161 1.25982 6.35017 1.46434L13.5663 12.0813C13.608 12.1266 13.6651 12.156 13.7306 12.156L13.7306 12.1563Z"
        fill="white"
      />
      <path
        d="M18.8044 0.576447C18.802 0.406218 18.6711 0.271496 18.5068 0.267822H18.1307C17.97 0.271496 17.8414 0.401317 17.8343 0.565426L17.8331 18.9919V18.9943C17.8319 19.118 17.7378 19.216 17.62 19.2234H13.9572C13.8774 19.2234 13.8155 19.1891 13.7477 19.107L6.48743 8.41902C6.44576 8.36759 6.38505 8.33452 6.31482 8.33452C6.19102 8.33452 6.08984 8.43862 6.08984 8.56599V8.57089V8.57456L6.09103 19.9063C6.09103 20.0802 6.22674 20.2186 6.39458 20.2186H6.75885C6.9136 20.2186 7.03978 20.0986 7.05882 19.9443V10.9281L13.1763 20.0032C13.3108 20.1722 13.4846 20.2187 13.5739 20.2187L18.4996 20.2212C18.6675 20.22 18.802 20.0816 18.802 19.9089H18.8008L18.8044 0.575571L18.8044 0.576447Z"
        fill="white"
      />
      <path
        d="M16.3901 0.581829C16.3901 0.407927 16.2544 0.269531 16.0866 0.269531H15.7223C15.5616 0.269531 15.4307 0.399352 15.42 0.562234V16.3581C15.42 16.4744 15.3283 16.5699 15.214 16.5699C15.145 16.5699 15.0819 16.5344 15.0426 16.4744L5.86474 3.57019L5.78855 3.45997C5.57071 3.1244 5.20645 2.92477 4.81601 2.92477H4.81362C4.16605 2.92477 3.63867 3.46608 3.63867 4.1335V19.9085C3.63867 20.0824 3.77438 20.2208 3.94222 20.2208H4.30648C4.46718 20.2208 4.59813 20.0922 4.60766 19.9305L4.60885 4.13361C4.60885 4.01727 4.70051 3.92296 4.8136 3.92296C4.88264 3.92296 4.94573 3.95848 4.98501 4.01849L14.1629 16.9227L14.2391 17.0329C14.4581 17.3661 14.82 17.5681 15.2116 17.5681H15.214C15.8616 17.5681 16.3877 17.0256 16.3877 16.3594L16.3901 0.581117L16.3901 0.581829Z"
        fill="white"
      />
      <path
        d="M47.9559 2.79217L40.8909 2.79462H40.7469C40.5791 2.79462 40.4434 2.93423 40.4434 3.10692H40.4446L40.4457 19.9105C40.4457 20.0832 40.5814 20.2228 40.7493 20.2228H41.1136C41.2695 20.2228 41.3945 20.1028 41.4135 19.9485L41.4159 4.02212H41.4147C41.4147 3.89353 41.5171 3.78821 41.6409 3.78821H47.9559C50.0927 3.78821 51.8306 5.57745 51.8306 7.77703C51.8306 9.9766 50.0927 11.7658 47.9559 11.7658H43.2991H43.1741H43.1706C43.0039 11.7683 42.8706 11.9079 42.8706 12.0781V12.4529C42.8706 12.6207 43.0003 12.7566 43.1622 12.764L47.9559 12.7627C50.626 12.7627 52.8009 10.5264 52.8009 7.77694C52.8009 5.02876 50.626 2.79114 47.9559 2.79114L47.9559 2.79217Z"
        fill="white"
      />
      <path
        d="M67.5894 2.79215L60.3817 2.79583C60.2126 2.79583 60.0781 2.93544 60.0781 3.10813H60.0793L60.0805 19.9117H60.0793C60.0793 20.0844 60.215 20.224 60.3841 20.224H60.7471C60.9031 20.224 61.0292 20.104 61.0483 19.9496V4.02333C61.0483 3.89474 61.1495 3.78941 61.2745 3.78941H67.5895C69.7263 3.78941 71.4655 5.57866 71.4655 7.77823C71.4655 9.9778 69.7263 11.767 67.5895 11.767H62.8077H62.8041C62.6374 11.7695 62.5041 11.9091 62.5041 12.0793V12.4541C62.5041 12.6219 62.6339 12.7578 62.7958 12.7652L67.5895 12.7639C70.2607 12.7639 72.4344 10.5276 72.4344 7.77815C72.4344 5.02865 70.2607 2.79114 67.5895 2.79114L67.5894 2.79215Z"
        fill="white"
      />
      <path
        d="M75.3435 19.945L71.5224 14.2771C71.4212 14.1032 71.5926 14.0395 71.5926 14.0395V14.0322C73.5258 12.674 74.7995 10.3924 74.7995 7.80706C74.7995 3.64915 71.5128 0.267822 67.4726 0.267822L57.9567 0.269047C57.7971 0.269047 57.6686 0.396412 57.6543 0.555631V19.9153C57.6591 20.0855 57.7924 20.2202 57.959 20.2202H58.3209C58.4864 20.2202 58.6209 20.0855 58.6233 19.9153V1.4888C58.6292 1.36388 58.728 1.26467 58.8482 1.26467H67.4714C70.9772 1.26467 73.8293 4.19905 73.8293 7.80582C73.8293 10.3312 72.4294 12.5234 70.3879 13.6121V13.6145C70.3879 13.6145 70.0248 13.7468 70.2629 14.1142L74.2638 20.0417C74.3543 20.1728 74.4162 20.2205 74.5364 20.2205H75.2304C75.4435 20.2205 75.4208 20.065 75.3435 19.945L75.3435 19.945Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8147">
        <rect
          width="74.6569"
          height="20.8345"
          fill="white"
          transform="translate(0.837891 0.240967)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
