<template>
  <svg
    role="img"
    class="wnyc-studios-logo"
    aria-label="WNYC Studios Logo"
    width="48"
    height="27"
    viewBox="0 0 48 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8133)">
      <path
        d="M0.351562 22.1216H1.57371L2.30099 24.5531L3.106 22.1093H4.10175L4.90657 24.5531L5.63385 22.1216H6.83235L5.4373 26.3544H4.42953L3.58885 23.9347L2.75419 26.3544H1.74642L0.351562 22.1216Z"
        fill="white"
      />
      <path
        d="M7.32422 22.1211H8.40332L10.1203 24.3426V22.1211H11.2651V26.3239H10.2516L8.46883 24.0184V26.3241H7.32422V22.1211Z"
        fill="white"
      />
      <path
        d="M13.2891 24.6967L11.7031 22.1211H13.0208L13.8793 23.598L14.7439 22.1211H16.0377L14.4518 24.679V26.3241H13.2891V24.6967Z"
        fill="white"
      />
      <path
        d="M15.9766 24.2338V24.2217C15.9766 22.9971 16.9126 22.0365 18.1766 22.0365C19.0291 22.0365 19.5779 22.3966 19.9473 22.9129L19.0768 23.5916C18.8384 23.2912 18.5641 23.0992 18.1646 23.0992C17.5804 23.0992 17.1689 23.5977 17.1689 24.21V24.2219C17.1689 24.8524 17.5804 25.3447 18.1646 25.3447C18.5998 25.3447 18.8562 25.1407 19.1067 24.8345L19.9772 25.4589C19.5837 26.0053 19.053 26.4076 18.1287 26.4076C16.9366 26.4074 15.9766 25.4888 15.9766 24.2338Z"
        fill="white"
      />
      <path
        d="M22.4857 23.993C23.416 24.1974 23.8451 24.5396 23.8451 25.188C23.8451 25.9146 23.2489 26.3828 22.42 26.3828C21.7583 26.3828 21.2157 26.1605 20.7148 25.7104L21.007 25.3621C21.4423 25.7582 21.8595 25.9567 22.4379 25.9567C22.9985 25.9567 23.3681 25.6624 23.3681 25.2362C23.3681 24.84 23.1593 24.6179 22.2828 24.4316C21.3229 24.2214 20.8815 23.9093 20.8815 23.2128C20.8815 22.5406 21.46 22.0602 22.2529 22.0602C22.861 22.0602 23.2962 22.2343 23.7195 22.5767L23.4452 22.9427C23.0576 22.6247 22.6701 22.4865 22.2409 22.4865C21.6984 22.4865 21.3526 22.7867 21.3526 23.171C21.353 23.5728 21.5677 23.7949 22.4857 23.993Z"
        fill="white"
      />
      <path
        d="M26.4054 26.3239H25.9286V22.5593H24.5273V22.1211H27.8067V22.5593H26.4054V26.3239Z"
        fill="white"
      />
      <path
        d="M32.1089 24.5289C32.1089 25.7535 31.4113 26.3901 30.3679 26.3901C29.3364 26.3901 28.6328 25.7535 28.6328 24.5648V22.1211H29.1038V24.5345C29.1038 25.4412 29.5809 25.9515 30.3799 25.9515C31.149 25.9515 31.6379 25.4834 31.6379 24.5648V22.1211H32.1089V24.5289Z"
        fill="white"
      />
      <path
        d="M34.7732 26.3239H33.3242V22.1211H34.7732C36.0849 22.1211 36.9911 23.0278 36.9911 24.2163C36.9911 25.4049 36.0849 26.3239 34.7732 26.3239ZM34.7732 22.5593H33.7954V25.8855H34.7732C35.8284 25.8855 36.5022 25.1652 36.5022 24.2286C36.5022 23.2917 35.8284 22.5593 34.7732 22.5593Z"
        fill="white"
      />
      <path
        d="M38.5179 22.1211V26.3239H38.0469V22.1211H38.5179Z"
        fill="white"
      />
      <path
        d="M41.6987 26.3952C40.4287 26.3952 39.5762 25.3927 39.5762 24.2279C39.5762 23.063 40.4407 22.0483 41.7107 22.0483C42.9807 22.0483 43.8332 23.0511 43.8332 24.2158C43.8331 25.3807 42.9687 26.3952 41.6987 26.3952ZM41.6987 22.4868C40.7448 22.4868 40.065 23.2552 40.065 24.2158C40.065 25.1764 40.7566 25.9572 41.7107 25.9572C42.6648 25.9572 43.3444 25.1885 43.3444 24.2281C43.3444 23.2673 42.6528 22.4868 41.6987 22.4868Z"
        fill="white"
      />
      <path
        d="M46.2885 23.993C47.2185 24.1974 47.6479 24.5396 47.6479 25.188C47.6479 25.9146 47.0516 26.3828 46.2228 26.3828C45.561 26.3828 45.0185 26.1605 44.5176 25.7104L44.8097 25.3621C45.245 25.7582 45.6624 25.9567 46.2406 25.9567C46.8012 25.9567 47.1706 25.6624 47.1706 25.2362C47.1706 24.84 46.9619 24.6179 46.0853 24.4316C45.1255 24.2214 44.6843 23.9093 44.6843 23.2128C44.6843 22.5406 45.2627 22.0602 46.0557 22.0602C46.6639 22.0602 47.0991 22.2343 47.5225 22.5767L47.2482 22.9427C46.8605 22.6247 46.473 22.4865 46.0439 22.4865C45.5013 22.4865 45.1555 22.7867 45.1555 23.171C45.1555 23.5728 45.3703 23.7949 46.2885 23.993Z"
        fill="white"
      />
      <path
        d="M0.347656 0.63623V20.0016H2.03543V17.115H11.6357V20.0016H13.3236V4.53327H22.9235V20.0016H24.6118V6.10319H34.2117V20.0016H35.9V2.45639H45.4993V20.0016H47.1875V0.63623H0.347656Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8133">
        <rect
          width="47.4904"
          height="26.0431"
          fill="white"
          transform="translate(0.347656 0.636597)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
