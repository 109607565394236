<template>
  <svg
    role="img"
    class="new-sounds-logo"
    aria-label="New Sounds Logo"
    width="112"
    height="13"
    viewBox="0 0 112 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8173)">
      <path
        d="M9.37094 12.061H7.37707L4.20729 4.95824L2.84398 12.061H0.679688L2.74172 1.25793H4.76969L7.90536 8.2823L9.25163 1.25793H11.433L9.37094 12.061Z"
        fill="white"
      />
      <path
        d="M13.3365 1.25793H20.0362L19.7216 3.04536H15.0193L14.5318 5.85193H18.6522L18.3377 7.62375H14.2172L13.7454 10.2736H18.6051L18.2905 12.061H11.4336L13.3365 1.25793Z"
        fill="white"
      />
      <path
        d="M32.8346 1.25793H35.0916L29.3274 12.061H27.5416L27.0541 5.73634L24.4706 12.061H22.5563L20.7539 1.25793H22.9947L24.0014 8.78405L26.8267 1.28433H28.483L29.0513 8.78405L32.8346 1.25793Z"
        fill="white"
      />
      <path
        d="M53.7247 8.82616C53.6269 9.58912 54.1989 10.3062 55.4087 10.3062C56.5368 10.3062 57.305 9.75693 57.4851 9.03981C57.6648 8.33787 57.2398 7.8649 56.2096 7.60549L54.8854 7.26978C52.5476 6.59842 52.4331 5.27097 52.6133 4.34015C52.9565 2.49383 54.9019 1.25793 56.9783 1.25793C59.1034 1.25793 60.5094 2.46334 60.117 4.43171H58.0245C58.1881 3.60772 57.6482 3.05841 56.602 3.05841C55.6702 3.05841 54.8367 3.5467 54.6892 4.29439C54.6239 4.63005 54.6566 5.19463 55.8011 5.4998L57.1253 5.88126C59.4958 6.52213 59.9208 7.84963 59.5776 9.08557C59.1034 10.9776 57.1253 12.061 55.0655 12.061C52.662 12.061 51.289 10.6267 51.6323 8.82616H53.7247Z"
        fill="white"
      />
      <path
        d="M65.9645 10.1357C67.9655 10.1357 69.854 8.59242 70.2092 6.66713C70.5643 4.7418 69.2892 3.18322 67.2878 3.18322C65.2705 3.18322 63.382 4.7418 63.0427 6.66713C62.6879 8.59242 63.9468 10.1357 65.9645 10.1357ZM67.6266 1.25793C70.7903 1.25793 72.8402 3.6722 72.275 6.66713C71.7266 9.63149 68.773 12.061 65.6093 12.061C62.4457 12.061 60.428 9.63149 60.9932 6.66713C61.5421 3.6722 64.4635 1.25793 67.6266 1.25793Z"
        fill="white"
      />
      <path
        d="M79.7119 8.15088L81.0198 1.25793H83.1223L81.8145 8.15088C81.3673 10.531 79.0828 12.061 76.7653 12.061C74.4478 12.061 72.7096 10.531 73.1562 8.15088L74.4809 1.25793H76.6327L75.3086 8.15088C75.0932 9.38727 75.9707 10.1909 77.1296 10.1909C78.2882 10.1909 79.4802 9.38727 79.7119 8.15088Z"
        fill="white"
      />
      <path
        d="M91.0956 12.061H89.1017L85.9319 4.95824L84.5683 12.061H82.4043L84.4663 1.25793H86.4942L89.63 8.2823L90.9764 1.25793H93.1576L91.0956 12.061Z"
        fill="white"
      />
      <path
        d="M94.8736 10.0777H97.1749C99.1662 10.0777 100.472 8.5191 100.831 6.6244C101.19 4.71438 100.504 3.12524 98.5134 3.12524H96.2121L94.8736 10.0777ZM92.4746 11.8654L94.4493 1.33752H98.8396C102.055 1.33752 103.491 3.6295 102.92 6.6244C102.349 9.60402 100.08 11.8654 96.8648 11.8654H92.4746Z"
        fill="white"
      />
      <path
        d="M105.349 8.79455C105.25 9.55859 105.821 10.2767 107.029 10.2767C108.155 10.2767 108.923 9.72663 109.102 9.00851C109.281 8.30558 108.857 7.83194 107.829 7.57217L106.507 7.23599C104.173 6.56369 104.059 5.23437 104.238 4.30224C104.581 2.45333 106.523 1.2157 108.596 1.2157C110.718 1.2157 112.121 2.4228 111.73 4.39393H109.641C109.804 3.56879 109.265 3.0187 108.221 3.0187C107.291 3.0187 106.458 3.50768 106.311 4.25642C106.246 4.59255 106.279 5.15792 107.421 5.46352L108.743 5.84552C111.109 6.48729 111.534 7.81665 111.191 9.05433C110.718 10.9491 108.743 12.0339 106.687 12.0339C104.288 12.0339 102.916 10.5976 103.259 8.79455H105.349Z"
        fill="white"
      />
      <path
        d="M43.1924 3.56818L43.9099 4.23988C44.4987 4.78782 45.3952 5.52914 46.3724 5.63586C47.3902 5.74713 48.0388 5.40786 48.4035 5.10377C48.8764 4.70953 49.148 4.14206 49.148 3.54666C49.148 2.43967 48.1609 1.15536 46.3311 0.955532C45.4436 0.858863 44.641 1.06616 44.0722 1.54019C43.5061 2.01189 43.2025 2.71246 43.1924 3.56818ZM39.2696 7.67884C38.471 7.67884 37.9413 7.96889 37.6249 8.23253C37.1519 8.62668 36.8807 9.19424 36.8807 9.78963C36.8807 10.8966 37.8678 12.1809 39.6972 12.3808C40.5848 12.4777 41.3875 12.2702 41.9563 11.7961C42.5221 11.3246 42.8257 10.6246 42.8361 9.76958L42.1185 9.09624C41.5298 8.54848 40.6332 7.80716 39.6562 7.70044C39.521 7.68567 39.3921 7.67884 39.2696 7.67884ZM40.1191 12.735C39.9674 12.735 39.813 12.7265 39.6562 12.7093C37.624 12.4874 36.5273 11.0393 36.5273 9.78963C36.5273 9.09989 36.8417 8.44231 37.3899 7.98547C37.9822 7.4919 38.8016 7.27382 39.6972 7.37187C40.783 7.49048 41.7415 8.27921 42.3681 8.86206L42.8365 9.3016L42.8389 3.61194C42.8389 2.63928 43.1841 1.83741 43.8373 1.29304C44.4825 0.755408 45.3826 0.518738 46.3724 0.626959C48.4044 0.848942 49.5012 2.29696 49.5012 3.54666C49.5012 4.23641 49.187 4.89398 48.6384 5.35083C48.0462 5.84431 47.2269 6.06221 46.3311 5.96443C45.2453 5.84581 44.2868 5.05709 43.6603 4.47415L43.1921 4.03564L43.1897 9.7244C43.1897 10.697 42.8444 11.4989 42.1912 12.0432C41.6482 12.4956 40.9246 12.7349 40.1191 12.735Z"
        fill="white"
      />
      <path
        d="M46.1744 12.7346C45.9834 12.7346 45.7923 12.665 45.6469 12.5259L43.8411 10.7958L43.8587 10.3369L45.8955 12.2882C46.0492 12.4351 46.2994 12.4352 46.4533 12.288C46.5278 12.2168 46.5688 12.122 46.5688 12.0212C46.5688 11.9205 46.5278 11.8257 46.4533 11.7545L37.4584 3.14956C37.1675 2.87129 37.1675 2.41849 37.4584 2.14022C37.7493 1.86195 38.2226 1.86195 38.5135 2.14022L49.1197 12.2864C49.2731 12.4335 49.5237 12.4335 49.6775 12.2864C49.8313 12.1393 49.8313 11.8999 49.6775 11.7528L40.6929 3.15795C40.402 2.87967 40.402 2.42688 40.6929 2.14861C40.9837 1.87033 41.4571 1.87033 41.7479 2.14861L43.5257 3.84941L43.5093 4.30946L41.4993 2.38649C41.3456 2.23934 41.0953 2.23934 40.9415 2.38649C40.7877 2.53355 40.7877 2.77296 40.9415 2.92006L49.9258 11.5149C50.217 11.7932 50.217 12.246 49.9258 12.5243C49.635 12.8025 49.1617 12.8025 48.871 12.5243L38.2648 2.3781C38.1111 2.23096 37.8607 2.23096 37.7071 2.3781C37.5532 2.52517 37.5532 2.76458 37.7071 2.91168L46.7019 11.5166C46.8428 11.6513 46.9206 11.8306 46.9206 12.0212C46.9206 12.2118 46.8428 12.3911 46.7018 12.5259C46.5564 12.665 46.3654 12.7346 46.1744 12.7346Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8173">
        <rect
          width="111.117"
          height="12.1535"
          fill="white"
          transform="translate(0.679688 0.581421)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
