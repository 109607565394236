<template>
  <svg
    role="img"
    class="wqxr-logo"
    aria-label="WQXR Logo"
    width="72"
    height="17"
    viewBox="0 0 72 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8164)">
      <path
        d="M32.3705 7.67194V7.64056C32.3705 4.4803 29.7051 1.94995 26.1061 1.94995C22.5067 1.94995 19.8086 4.51187 19.8086 7.67194V7.70332C19.8086 10.8636 22.4735 13.3945 26.0726 13.3945C26.2381 13.3945 26.3999 13.384 26.562 13.3727C26.893 13.9699 27.3495 14.5297 27.9434 15.0125C28.677 15.6121 29.618 16.0665 30.7105 16.3112L32.5004 13.8034C31.0638 13.9152 30.1524 13.1493 29.638 12.4231C31.3122 11.3954 32.3705 9.65056 32.3705 7.67194ZM29.0723 7.70314C29.0723 9.28994 27.8885 10.6428 26.1061 10.6428C24.3405 10.6428 23.124 9.25987 23.124 7.67194V7.64056C23.124 6.05282 24.3054 4.70073 26.0724 4.70073C27.8556 4.70073 29.0721 6.08364 29.0721 7.67194V7.70314H29.0723Z"
        fill="white"
      />
      <path
        d="M4.29632 12.9971H7.04095L9.31581 6.69939L11.6055 12.9971H14.3519L18.1523 1.98148H14.8878L12.9057 8.30993L10.713 1.94995H7.9992L5.80607 8.30993L3.82461 1.98148H0.494141L4.29632 12.9971Z"
        fill="white"
      />
      <path
        d="M34.707 12.9971H38.0951L40.1938 9.66681L42.2768 12.9971H45.7434L42.0128 7.34683L45.5883 1.94995H42.1999L40.2563 5.05849L38.3288 1.94995H34.8625L38.438 7.37828L34.707 12.9971Z"
        fill="white"
      />
      <path
        d="M59.1826 5.75266V5.72102C59.1826 4.63228 58.8584 3.78024 58.2265 3.14841C57.5022 2.3917 56.3601 1.94995 54.7101 1.94995H49.6055V12.9971H52.5978V9.65089H53.9082L56.0831 12.9971H59.5382L56.9614 9.14528C58.3032 8.56178 59.1826 7.44158 59.1826 5.75266ZM56.176 5.94236C56.176 6.74703 55.5899 7.25226 54.5721 7.25226H52.5978V4.60044H54.5566C55.559 4.60044 56.176 5.04257 56.176 5.91053V5.94236Z"
        fill="white"
      />
      <path
        d="M70.0234 15.2061H71.6789V0.844849H70.0234V15.2061Z"
        fill="white"
      />
      <path
        d="M65.6105 7.47468C66.2192 7.47468 66.7131 6.97946 66.7131 6.36888C66.7131 5.75917 66.2192 5.26526 65.6105 5.26526C64.9998 5.26526 64.5059 5.75896 64.5059 6.36888C64.5056 6.97946 64.9998 7.47468 65.6105 7.47468Z"
        fill="white"
      />
      <path
        d="M65.6105 11.3399C66.2192 11.3399 66.7131 10.8451 66.7131 10.2352C66.7131 9.62615 66.2192 9.13049 65.6105 9.13049C64.9998 9.13049 64.5059 9.62615 64.5059 10.2352C64.5056 10.8453 64.9998 11.3399 65.6105 11.3399Z"
        fill="white"
      />
      <path
        d="M68.3672 15.2061H68.919V0.844849H68.3672V15.2061Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8164">
        <rect
          width="71.1845"
          height="15.6259"
          fill="white"
          transform="translate(0.494141 0.845215)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
